import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../components/mdx/page";
import { ContentPlusDecor } from "../components/widgets/content-plus-decor";
import { FaHeartBroken } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`404: Page not Found`}</h1>
    <ContentPlusDecor decor={<FaHeartBroken mdxType="FaHeartBroken" />} mdxType="ContentPlusDecor">
      <h2>{`We're sorry -`}</h2>
      <p><strong parentName="p">{`That link has no power here.`}</strong></p>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      